<template>
  <div class="zpi-demo">
    <section class="section">
      <div class="container">
        <div class="field">
          <label for="model" class="label">ZPI-Modell auswählen</label>
        <div class="select"> 
                  <select name="model" id="select_model" v-model="model">
                    <option v-for="model in models" :key="model" :value="model">{{model}}</option>
                  </select>
        </div>
        </div>
        <div class="card">
          <div class="card-content">
            <h3 class="title">Testdaten zur Auswertung eingeben</h3>
            <form class="form" @submit="fetchCase">
              <div class="field">
                
                <div class="one-fourth">
                  <label for="case" class="label">Vordefinierte Klärfälle</label>
                  <div class="control">
                    <div class="select">
                      <select
                        name="case"
                        id="select_case"
                        @change="defaultCaseSelected"
                        v-model="selectedCase"
                      >
                        <option
                          v-for="(value, testCase) in testCases"
                          :key="testCase"
                          :value="testCase"
                        >
                          {{ testCase }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <!--<div class="divider"></div>-->
              <hr />
              <!--
              <div class="field">
                <label for="counter_nr" class="label">Zählernummer</label>
                <div class="control">
                  <input
                    type="text"
                    class="input"
                    name="counter_nr"
                    v-model="counter_nr"
                  />
                </div>
              </div> -->
              <div class="field level">
                <div class="one-half">
                  <label for="counter_nr" class="label"
                    >Zählernummer
                    <span data-tooltip="Die Gerätenummer des Zählers">
                      <img class="label-icon" src="../assets/info.svg" />
                    </span>
                  </label>
                  <input
                    type="text"
                    class="input"
                    name="counter_nr"
                    v-model="counter_nr"
                  />
                </div>
                <div v-if="model=='Lieferanten'" class="one-half">
                  <label for="malo_id" class="label"
                    >MaLo-ID
                    <span data-tooltip="Marklokations ID. Wird nur im Lieferantenmodell berücksichtigt.">
                      <img class="label-icon" src="../assets/info.svg" />
                    </span>
                  </label>
                  <input
                    type="text"
                    class="input"
                    name="malo_id"
                    v-model="malo_id"
                  />
                </div>
              </div>
              <div class="field level">
                <div class="one-half">
                  <label for="first_name" class="label">Vorname</label>
                  <input
                    type="text"
                    class="input"
                    name="first_name"
                    v-model="first_name"
                  />
                </div>
                <div class="one-half">
                  <label for="last_name" class="label">Nachname</label>
                  <input
                    type="text"
                    class="input"
                    name="last_name"
                    v-model="last_name"
                  />
                </div>
              </div>
              <div class="field level">
                <div class="level-left one-half space-between">
                  <div class="one-half">
                    <label for="street" class="label">Straße</label>
                    <input
                      type="search"
                      class="input"
                      name="street"
                      v-model="street"
                    />
                  </div>
                  <div class="one-fourth">
                    <label for="house_nr" class="label">Hausnummer</label>
                    <input
                      type="search"
                      class="input"
                      name="house_nr"
                      v-model="house_nr"
                    />
                  </div>
                  <div class="one-fourth">
                    <label for="house_nr2" class="label">
                      -zusatz
                      <span
                        data-tooltip="Der Hausnummerzusatz ist meist ein Buchstabe im Bereich a-f."
                        class="has-tooltip-multiline"
                      >
                        <img class="label-icon" src="../assets/info.svg" />
                      </span>
                    </label>
                    <input
                      type="text"
                      class="input"
                      name="house_nr2"
                      v-model="house_nr2"
                    />
                  </div>
                </div>
                <div class="level-right one-half space-between">
                  <div class="one-half">
                    <label for="postcode" class="label"
                      >Postleitzahl
                      <span
                        data-tooltip="Die Postleitzahl ist eine 5-stellige Nummer. Es sind nur PLZ aus dem Raum München zu finden"
                        class="has-tooltip-multiline"
                      >
                        <img class="label-icon" src="../assets/info.svg" />
                      </span>
                    </label>
                    <input
                      type="search"
                      class="input"
                      name="postcode"
                      v-model="postcode"
                    />
                  </div>
                  <div class="one-half">
                    <label for="city" class="label"
                      >Ort
                      <span
                        data-tooltip="München ist der einzige Ort im Datensatz."
                      >
                        <img class="label-icon" src="../assets/info.svg" />
                      </span>
                    </label>
                    <input
                      type="search"
                      class="input"
                      name="city"
                      v-model="city"
                    />
                  </div>
                </div>
              </div>
              <div class="level">
                <div class="level-left">
                  <button
                    class="button is-primary"
                    type="submit"
                    @click="fetchCase"
                  >
                    Identifizierung durchführen
                  </button>
                </div>
                <div class="level-right" id="column-switch-button">
                  <switch-button
                    storage="columnTitle"
                    value1="Standard"
                    value2="SAP"
                    labelText="Spaltennamen:"
                    @column-switch="switchColumns"
                  />
                </div>
              </div>
            </form>

            <!--
          <button class="button is-primary">Primary</button>
          <button class="button is-warning">Warning</button>
          <button class="button is-link">Link</button>
          <button class="button is-info">Info</button>
          --></div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container container-for-table">
        <div class="notification is-danger" v-if="error">
          <button class="delete" @click="clearError"></button>
          <p>{{ error }}</p>
          <p>Bitte versuchen Sie es später nochmal.</p>
        </div>
        <h3 class="subtitle has-text-weight-semibold has-text-dark">
          Ergebnisse
          <span data-tooltip="Erläuterungen zu den Ergebnissen">
            <img
              class="hand-hover label-icon"
              @click="showModal"
              src="../assets/info.svg"
            />
          </span>
        </h3>
        <div v-if="resultsLoading" class="block">
          <p class="block" v-if="initialLoading">
            Daten werden geladen.
          </p>
          <p class="block" v-else>
            Die Bearbeitung eines Klärfalls kann einen Moment dauern.
          </p>
          <progress
            class="progress is-small is-primary block"
            max="100"
          ></progress>
        </div>
        <div class="block" v-else-if="results.length == 0 && fetchedResults">
          <p class="has-text-dark">
            Für den angegebenen Klärfall konnten keine Vorschläge gefunden
            werden.
          </p>
        </div>
        <div class="block" v-else-if="results.length == 0">
          <p class="has-text-dark">
            Keine Ergebnisse vorhanden. Klicken Sie auf "Identifizierung
            durchführen" um eine Zählpunktidentifikation durchzuführen.
          </p>
        </div>
        <!--add 'is-selected' modifier to selected table-->
        <div v-else class="table-container">
          <table
            class="table is-bordered is-fullwidth table-results"
            id="case-results"
          >
            <thead class="has-background-grey-dark">
              <tr>
                <th
                  data-tooltip="Marktlokations-ID"
                  class="
                    has-tooltip-primary has-tooltip-arrow has-tooltip-right
                  "
                >
                  {{ columnMaloID }}
                </th>
                <th
                  data-tooltip="Straße"
                  class="
                    has-tooltip-primary has-tooltip-arrow has-tooltip-bottom
                  "
                >
                  {{ columnStreet }}
                </th>
                <th
                  data-tooltip="Hausnummer"
                  class="
                    has-tooltip-primary has-tooltip-arrow has-tooltip-bottom
                  "
                >
                  {{ columnHS_Nr }}
                </th>
                <th
                  data-tooltip="Hausnummerzusatz"
                  class="
                    has-tooltip-primary has-tooltip-arrow has-tooltip-bottom
                  "
                >
                  {{ columnsHS_Nr2 }}
                </th>
                <th
                  data-tooltip="Postleitzahl"
                  class="
                    has-tooltip-primary has-tooltip-arrow has-tooltip-bottom
                  "
                >
                  {{ columnPLZ }}
                </th>
                <th
                  data-tooltip="Ort"
                  class="
                    has-tooltip-primary has-tooltip-arrow has-tooltip-bottom
                  "
                >
                  {{ columnOrt }}
                </th>
                <th
                  data-tooltip="Vorname"
                  class="
                    has-tooltip-primary has-tooltip-arrow has-tooltip-bottom
                  "
                >
                  {{ columnName1 }}
                </th>
                <th
                  data-tooltip="Nachname"
                  class="
                    has-tooltip-primary has-tooltip-arrow has-tooltip-bottom
                  "
                >
                  {{ columnName2 }}
                </th>
                <th
                  data-tooltip="Gerätenummer"
                  class="
                    has-tooltip-primary has-tooltip-arrow has-tooltip-bottom
                  "
                >
                  {{ columnGeraet }}
                </th>
                <th
                  data-tooltip="Wahrscheinlichkeit"
                  class="has-tooltip-primary has-tooltip-arrow has-tooltip-left"
                >
                  {{ columnProbability }}
                </th>
              </tr>
            </thead>
            <tbody ref="results_body" @click="showModal" class="hand-hover">
              <tr
                v-for="(result, index) in results"
                :key="result"
                :class="{ [firstResultColor]: index == 0 }"
              >
                <td class="td-results">{{ result.EXT_UI }}</td>
                <td class="td-results">{{ result.AO_STR }}</td>
                <td class="td-results">{{ result.AO_HSNR1 }}</td>
                <td class="td-results">{{ result.AO_HSNR2 }}</td>
                <td class="td-results">{{ result.AO_PLZ }}</td>
                <td class="td-results">{{ result.AO_ORT }}</td>
                <td class="td-results">{{ result.GP_NAME1 }}</td>
                <td class="td-results">{{ result.GP_NAME2 }}</td>
                <td class="td-results">{{ result.GERAET }}</td>
                <td class="td-results">
                  {{ String((result.probability * 100).toFixed(2)) + "%" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <div class="modal" v-bind:class="{ 'is-active': modalIsActive }">
      <div class="modal-background" @click="hideModal"></div>
      <div class="modal-content">
        <div class="card">
          <div class="card-content has-text-dark">
            <h4 class="is-size-4">Ergebnisse</h4>
            <p class="mb-1">
              Der eingegebene Klärfall wurde bearbeitet und mit allen
              vorhandenen Zählpunkten verglichen, die die gleiche Postleitzahl
              aufweisen.
            </p>
            <p class="mb-2">
              Die Tabelle zeigt die zehn wahrscheinlichsten Übereinstimmungen
              an. Die Farbe der ersten Zeile gibt Aufschluss über die
              Entscheidung des ML-Modells:
            </p>
            <p>
              <span class="has-text-success has-text-weight-semibold"
                >Grün:
              </span>
              Die Übereinstimmungswahrscheinlichkeit ist hoch genug. Der
              Zählpunkt wurde automatisch zugewiesen.<br />
              <span class="has-text-info has-text-weight-semibold">Blau: </span>
              Die Übereinstimmungswahrscheinlichkeit ist nicht hoch genug. Der
              Klärfall wird zur Bearbeitung an einen Sachbearbeiter
              ausgesteuert.<br />
              <span class="has-text-danger has-text-weight-semibold"
                >Rot:
              </span>
              Die Übereinstimmungswahrscheinlichkeit ist gering. Der Klärfall
              wird zur Korrektur zurückgesendet.<br />
            </p>
            <h4 class="is-size-4 mt-2">Kein Ergebnis?</h4>

            <p>
              Wenn kein Ergebnis vorliegt, so wurde ein Klärfall mit einer
              unbekannten Postleitzahl abgeschickt.
            </p>
          </div>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="hideModal"
      ></button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ApiService from "@/apiService";
import SwitchButton from "@/components/SwitchButton";
import { mapGetters } from "vuex";

export default {
  name: "ZpiDemo",
  data() {
    return {
      error: "",
      resultsLoading: false,
      columnTitle: "",
      modalIsActive: false,
      fetchedResults: true,
      initialLoading: false,
      models: ["Netzbetreiber", "Lieferanten"]
    };
  },
  components: {
    SwitchButton,
  },
  methods: {
    clearError() {
      this.error = "";
    },
    generateCase() {
      let caseGen = {
        GERAET: this.counter_nr,
        GP_NAME1: this.first_name,
        GP_NAME2: this.last_name,
        AO_STR: this.street,
        AO_HSNR1: this.house_nr,
        AO_HSNR2: this.house_nr2,
        AO_PLZ: this.postcode,
        AO_ORT: this.city,
        EXT_UI: this.malo_id
      }
      return caseGen;
    },
    setResults(resultsParam) {
      let method = this.model == "Netzbetreiber" ? "setResultsNetz" : "setResultsLief";
      try {
        this.$store.commit(
          method,
          resultsParam.sort((a, b) => b.probability - a.probability)
        );
      } catch (err) {
        console.log(err);
        this.$store.commit(method, []);
      }
    },
    setDefaultCase(testCase, caseName) {
      let c = testCase.case;
      this.$store.commit("setCase", c);
      this.$store.commit("setSelectedCase", caseName);
      //let results = this.model == "Netzbetreiber" ? "resultsNetz" : "resultsLief";
      //this.setResults(testCase[results]);
      this.fetchedResults = false;
    },
    defaultCaseSelected(event) {
      console.log(this.$store.state.testCases);
      this.setDefaultCase(
        this.testCases[event.target.value],
        event.target.value
      );
    },
    fetchCase: async function (e) {
      // send request for zpi to server
      this.fetchedResults = true;
      this.resultsLoading = true;
      this.clearError();
      e.preventDefault();
      let payload = {
        case: this.generateCase(),
        model: this.model,
      };
      let response = ApiService.getCase(payload);
      response
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            // handle error
            let errMsg = await res.json();
            throw new Error(res.status + ": " + errMsg.error);
          }
        })
        .then((res) => {
          this.setResults(res);
          this.resultsLoading = false;
        })
        .catch((err) => {
          this.error = err.message;
          console.log(err);
          this.resultsLoading = false;
        });
    },
    switchColumns(columnValue) {
      this.columnTitle = columnValue;
    },
    showModal() {
      this.modalIsActive = true;
      console.log(this.modalIsActive);
    },
    hideModal() {
      this.modalIsActive = false;
    },
  },
  computed: {
    // background color of the first entry in results table
    firstResultColor: function () {
      let probability = this.results[0].probability;
      if (probability >= 0.9) {
        return "has-background-success";
      } else if (probability >= 0.7) {
        return "has-background-info";
      } else {
        return "has-background-danger";
      }
    },
    // Titles of the table columns
    columnMaloID: function () {
      return this.columnTitle == "Standard" ? "Malo_ID" : "EXT_UI";
    },
    columnStreet() {
      return this.columnTitle == "Standard" ? "Straße" : "AO_STR";
    },
    columnHS_Nr() {
      return this.columnTitle == "Standard" ? "Hausnr." : "AO_HSNR1";
    },
    columnsHS_Nr2() {
      return this.columnTitle == "Standard" ? "-zusatz" : "AO_HSNR2";
    },
    columnPLZ() {
      return this.columnTitle == "Standard" ? "PLZ" : "AO_PLZ";
    },
    columnOrt() {
      return this.columnTitle == "Standard" ? "Ort" : "AO_ORT";
    },
    columnName1() {
      return this.columnTitle == "Standard" ? "Vorn." : "GP_NAME1";
    },
    columnName2() {
      return this.columnTitle == "Standard" ? "Nachn." : "GP_NAME2";
    },
    columnGeraet() {
      return this.columnTitle == "Standard" ? "Gerät" : "GERAET";
    },
    columnProbability() {
      return this.columnTitle == "Standard" ? "WSK" : "PROBA";
    },
    counter_nr: {
      get() {
        return this.$store.getters.counter_nr;
      },
      set(value) {
        this.$store.commit("setCounter_nr", value);
        this.$store.commit("setCustomCase", this.generateCase());
      },
    },
    first_name: {
      get() {
        return this.$store.getters.first_name;
      },
      set(value) {
        this.$store.commit("setFirst_name", value);
        this.$store.commit("setCustomCase", this.generateCase());
      },
    },
    last_name: {
      get() {
        return this.$store.getters.last_name;
      },
      set(value) {
        this.$store.commit("setLast_name", value);
        this.$store.commit("setCustomCase", this.generateCase());
      },
    },
    street: {
      get() {
        return this.$store.getters.street;
      },
      set(value) {
        this.$store.commit("setStreet", value);
        this.$store.commit("setCustomCase", this.generateCase());
      },
    },
    house_nr: {
      get() {
        return this.$store.getters.house_nr;
      },
      set(value) {
        this.$store.commit("setHouse_nr", value);
        this.$store.commit("setCustomCase", this.generateCase());
      },
    },
    house_nr2: {
      get() {
        return this.$store.getters.house_nr2;
      },
      set(value) {
        this.$store.commit("setHouse_nr2", value);
        this.$store.commit("setCustomCase", this.generateCase());
      },
    },
    postcode: {
      get() {
        return this.$store.getters.postcode;
      },
      set(value) {
        this.$store.commit("setPostcode", value);
        this.$store.commit("setCustomCase", this.generateCase());
      },
    },
    city: {
      get() {
        return this.$store.getters.city;
      },
      set(value) {
        this.$store.commit("setCity", value);
        this.$store.commit("setCustomCase", this.generateCase());
      },
    },
    malo_id: {
      get() {
        return this.$store.getters.malo_id;
      },
      set(value) {
        this.$store.commit("setMaloId", value);
        this.$store.commit("setCustomCase", this.generateCase());
      },
    },
    model: {
      get() {
        return this.$store.getters.model;
      },
      set(value) {
        this.$store.commit("setModel", value);
      },
    },
    testCases: {
      get() {
        return this.$store.getters.testCases;
      },
      set(value) {
        this.$store.commit("setTestCases", value);
        this.$store.commit("setCustomCase", this.generateCase());
      },
    },
    results: {
      get() {
        let results = this.model == "Netzbetreiber" ? "resultsNetz" : "resultsLief";
        if (!this.selectedCase) return [];
        return this.$store.getters.testCases[this.$store.getters.selectedCase][results];
      }
    },
    ...mapGetters(["selectedCase"]),
  },
  mounted: async function loadTestCases() {
    if (localStorage.columnTitle) this.columnTitle = localStorage.columnTitle;
    this.$nextTick(function () {
      this.resultsLoading = true;
      this.initialLoading = true;
      

      if (!this.testCases[Object.keys(this.testCases)[0]].resultsNetz) {
          this.resultsLoading = true;
          this.initialLoading = true;
          // fetch Netzbetreiber Restults
          let resultsNetz = ApiService.getDefaultNetzbetreiberResults();
          resultsNetz
            .then(async (res) => {
              if (res.ok) {
                return res.json();
              } else {
                // handle error
                let errMsg = await res.json();
                throw new Error(res.status + ": " + errMsg.error);
              }
            })
            .then((res) => {
              for (let caseName in this.$store.getters.testCases) {
                this.$store.commit(
                  "updateCase", {caseName: caseName, key: "resultsNetz", value: res[caseName]["resultsNetz"]});
              }
              
              if (this.model == "Netzbetreiber") {
                if (this.$store.getters.selectedCase === "") {
                  let caseName = Object.keys(this.testCases)[0];
                  this.setDefaultCase(this.testCases[caseName], caseName);
                }
                this.resultsLoading = false;
                this.initialLoading = false;  
              }
            })
            .catch((err) => {
              if (this.model == "Netzbetreiber") {
                this.resultsLoading = false;
                this.initialLoading = false;  
              }
              this.error = err.message;
            });
      }
      if (!this.testCases[Object.keys(this.testCases)[0]].resultsLief) {
            // fetch Lieferanten Restults
          let resultsNetz = ApiService.getDefaultLieferantenResults();
          resultsNetz
            .then(async (res) => {
              if (res.ok) {
                return res.json();
              } else {
                // handle error
                let errMsg = await res.json();
                throw new Error(res.status + ": " + errMsg.error);
              }
            })
            .then((res) => {
              for (let caseName in this.$store.getters.testCases) {
                this.$store.commit(
                  "updateCase", {caseName: caseName, key: "resultsLief", value: res[caseName]["resultsLief"]});
              }
              
              if (this.model == "Lieferanten") {
                if (this.$store.getters.selectedCase === "") {
                  let caseName = Object.keys(this.testCases)[0];
                  this.setDefaultCase(this.testCases[caseName], caseName);
                }
                this.resultsLoading = false;
                this.initialLoading = false;  
              }
              
            })
            .catch((err) => {
              if (this.model == "Lieferanten") {
                this.resultsLoading = false;
                this.initialLoading = false;  
              }
              this.error = err.message;
            });
        }

      this.initialLoading = false;
      this.resultsLoading = false;
    });
  },
};
</script>
