<template>
  <div>
    <section class="section">
      <div class="container">
        <div class="card">
          <div class="card-content">
            <h1 class="title">Datengrundlage</h1>
            <h3 class="subtitle">Daten filtern:</h3>
            <form action="" class="form block">
              <div class="field level">
                <div class="one-half">
                  <label for="jump_to" class="label">
                      Springe zu ID 
                      <span
                      data-tooltip="Der Testdatensatz umfasst 49997 Einträge">
                      <img class="label-icon" src="../assets/info.svg" />
                        </span>
                    </label>
                  <input
                    type="search"
                    class="input"
                    name="jump_to"
                    placeholder="4000"
                    v-model="jump_to"
                  />
                </div>
              </div>
              <div class="field level">
                <div class="one-half">
                  <label for="malo_id" class="label">
                      Malo_id
                      <span
                      data-tooltip="Die Marktlokations-ID ist eine Identifikationsnummer für Anschlussobjekte.
Darüber kann eindeutig ein Zählpunkt zugewiesen werden" class="has-tooltip-multiline">
                      <img class="label-icon" src="../assets/info.svg" />
                        </span>
                  </label>
                  <input
                    type="search"
                    class="input"
                    name="malo_id"
                    v-model="malo_id"
                  />
                </div>
                <div class="one-half">
                  <label for="counter_nr" class="label">
                      Zählernummer
                      <span
                      data-tooltip="Die Gerätenummer des Zählers">
                      <img class="label-icon" src="../assets/info.svg" />
                        </span>
                  </label>
                  <input
                    type="search"
                    class="input"
                    name="counter_nr"
                    v-model="counter_nr"
                  />
                </div>
              </div>
              <div class="field level">
                <div class="one-half">
                  <label for="first_name" class="label">Vorname</label>
                  <input
                    type="search"
                    class="input"
                    name="first_name"
                    v-model="first_name"
                  />
                </div>
                <div class="one-half">
                  <label for="last_name" class="label">Nachname</label>
                  <input
                    type="search"
                    class="input"
                    name="last_name"
                    v-model="last_name"
                  />
                </div>
              </div>
              <div class="field level">
                <div class="level-left one-half space-between">
                  <div class="one-half">
                  <label for="street" class="label">Straße</label>
                  <input
                    type="search"
                    class="input"
                    name="street"
                    v-model="street"
                  />
                </div>
                <div class="one-fourth">
                  <label for="house_nr" class="label">Hausnummer</label>
                  <input
                    type="search"
                    class="input"
                    name="house_nr"
                    v-model="house_nr"
                  />
                </div>
                <div class="one-fourth">
                  <label for="house_nr2" class="label">
                      -zusatz
                      <span
                      data-tooltip="Der Hausnummerzusatz ist meist ein Buchstabe im Bereich a-f." class="has-tooltip-multiline">
                      <img class="label-icon" src="../assets/info.svg" />
                        </span>
                      </label>
                  <input
                    type="text"
                    class="input"
                    name="house_nr2"
                    v-model="house_nr2"
                  />
                </div>
                </div>
                <div class="level-right one-half space-between">
                <div class="one-half">
                  <label for="postcode" class="label">Postleitzahl
                      <span
                      data-tooltip="Die Postleitzahl ist eine 5-stellige Nummer. Es sind nur PLZ aus dem Raum München zu finden" class="has-tooltip-multiline">
                      <img class="label-icon" src="../assets/info.svg" />
                        </span>
                  </label>
                  <input
                    type="search"
                    class="input"
                    name="postcode"
                    v-model="postcode"
                  />
                </div>
                <div class="one-half">
                  <label for="city" class="label"
                    >Ort
                    <span
                      data-tooltip="München ist der einzige Ort im Datensatz."
                    >
                      <img class="label-icon" src="../assets/info.svg" />
                    </span>
                  </label>
                  <input
                    type="search"
                    class="input"
                    name="city"
                    v-model="city"
                  />
                </div>
                </div>
              </div>
              <div class="field level">
                <div class="one-half level-left">
                  <button
                    class="button is-primary has-margin"
                    type="submit"
                    @click="fetchData"
                  >
                    Filter anwenden
                  </button>
                  <button
                    class="button is-warning has-margin"
                    @click="resetFilter"
                  >
                    Filter zurücksetzen
                  </button>
                </div>
                <div class="level-right" id="column-switch-button">
                    <switch-button
                      storage="columnTitle"
                      value1="Standard"
                      value2="SAP"
                      labelText="Spaltennamen:"
                      @column-switch="switchColumns"
                    />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container container-for-table">
        <progress
          v-if="dataLoading"
          class="progress is-small is-primary block"
          max="100"
        ></progress>
        <div v-else class="block">
          <div id="length-info" v-if="dataLength">
            <p>Es wurden {{ dataLength }} Einträge gefunden.</p>
          </div>
          <div class="table-container table-data">
            <table class="table is-bordered is-fullwidth" id="data-table">
              <thead class="has-background-primary">
                <tr>
                  <th>ID</th>
                  <th
                    data-tooltip="Marktlokations-ID"
                    class="
                      has-tooltip-info has-tooltip-arrow has-tooltip-bottom
                    "
                  >
                    {{ columnMaloID }}
                  </th>
                  <th
                    data-tooltip="Straße"
                    class="
                      has-tooltip-info has-tooltip-arrow has-tooltip-bottom
                    "
                  >
                    {{ columnStreet }}
                  </th>
                  <th
                    data-tooltip="Hausnummer"
                    class="
                      has-tooltip-info has-tooltip-arrow has-tooltip-bottom
                    "
                  >
                    {{ columnHS_Nr }}
                  </th>
                  <th
                    data-tooltip="Hausnummerzusatz"
                    class="
                      has-tooltip-info has-tooltip-arrow has-tooltip-bottom
                    "
                  >
                    {{ columnsHS_Nr2 }}
                  </th>
                  <th
                    data-tooltip="Postleitzahl"
                    class="
                      has-tooltip-info has-tooltip-arrow has-tooltip-bottom
                    "
                  >
                    {{ columnPLZ }}
                  </th>
                  <th
                    data-tooltip="Ort"
                    class="
                      has-tooltip-info has-tooltip-arrow has-tooltip-bottom
                    "
                  >
                    {{ columnOrt }}
                  </th>
                  <th
                    data-tooltip="Vorname"
                    class="
                      has-tooltip-info has-tooltip-arrow has-tooltip-bottom
                    "
                  >
                    {{ columnName1 }}
                  </th>
                  <th
                    data-tooltip="Nachname"
                    class="
                      has-tooltip-info has-tooltip-arrow has-tooltip-bottom
                    "
                  >
                    {{ columnName2 }}
                  </th>
                  <th
                    data-tooltip="Gerätenummer"
                    class="
                      has-tooltip-info has-tooltip-arrow has-tooltip-bottom
                    "
                  >
                    {{ columnGeraet }}
                  </th>
                </tr>
              </thead>
              <tbody ref="data_body" id="data_body">
                <tr v-for="entry in testdata" :key="entry" @click="setCustomCase(entry)" title="Daten in das ZPI-Formular übernehmen." class="hand-hover data-row">
                  <td class="td-data">{{ entry.Index }}</td>
                  <td class="td-data">{{ entry.EXT_UI }}</td>
                  <td class="td-data">{{ entry.AO_STR }}</td>
                  <td class="td-data">{{ entry.AO_HSNR1 }}</td>
                  <td class="td-data">{{ entry.AO_HSNR2}}</td>
                  <td class="td-data">{{ entry.AO_PLZ }}</td>
                  <td class="td-data">{{ entry.AO_ORT }}</td>
                  <td class="td-data">{{ entry.GP_NAME1 }}</td>
                  <td class="td-data">{{ entry.GP_NAME2 }}</td>
                  <td class="td-data">{{ entry.GERAET }}</td>
                </tr>
                <tr v-if="dataIsNotExhausted">
                  <td colspan="10">
                    <Observer @intersect="loadMoreData" :options="observerOptions"/>
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>
        </div>
        <!--
        <tr>
              <td colspan="10">
            <progress
        class="progress is-small is-primary"
        max="100"
        
      ></progress></td></tr>
    -->
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import ApiService from "@/apiService";
import SwitchButton from "@/components/SwitchButton";
import Observer from "@/components/Observer";

export default {
  name: "TestData",
  components: {
    Observer,
    SwitchButton,
  },
  data() {
    return {
      dataLoading: true,
      testdata: [],
      error: "",
      jump_to: "",
      malo_id: "",
      counter_nr: "",
      first_name: "",
      last_name: "",
      street: "",
      house_nr: "",
      house_nr2: "",
      postcode: "",
      city: "",
      columnTitle: "",
      dataIsNotExhausted: true,
      observerOptions: {},
      dataLength: "",
    };
  },
  methods: {
    loadTestData() {
      this.dataLoading = true;
      let params = {
        Index: this.jump_to,
        EXT_UI: this.malo_id,
        GP_NAME1: this.first_name,
        GP_NAME2: this.last_name,
        AO_STR: this.street,
        AO_HSNR1: this.house_nr,
        AO_HSNR2: this.house_nr2,
        AO_PLZ: this.postcode,
        AO_ORT: this.city,
        GERAET: this.counter_nr,
        requestLength: 75,
      };
      // remove empty parameters
      for (let param in params) {
        if (params[param] === "") {
          delete params[param];
        }
      }
      let response = ApiService.getTestData(params);
      response
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            // handle error
            let errMsg = await res.json();
            throw new Error(res.status + ": " + errMsg.error);
          }
        })
        .then((res) => {
          this.testdata = res.data;
          this.dataIsNotExhausted = !res.exhausted;
          this.dataLength = res.dataLength;
          this.dataLoading = false;
        })
        .catch((err) => {
          this.error = err.message;
          console.log(this.error);
        });
    },
    fetchData(e) {
      e.preventDefault();
      this.loadTestData();
    },
    resetFilter() {
      this.malo_id = "";
      this.counter_nr = "";
      this.first_name = "";
      this.last_name = "";
      this.street = "";
      this.house_nr = "";
      this.postcode = "";
      this.city = "";
      this.loadTestData();
    },
    switchColumns(columnValue) {
      this.columnTitle = columnValue;
    },
    infiniteHandler($state) { 
      // handle infinite loading
      console.log('infinite')
      $state.loaded;
    },
    loadMoreData() {
      let params = {
        Index: parseInt(this.testdata[this.testdata.length-1].Index, 10) + 1,
        EXT_UI: this.malo_id,
        GP_NAME1: this.first_name,
        GP_NAME2: this.last_name,
        AO_STR: this.street,
        AO_HSNR1: this.house_nr,
        AO_HSNR2: this.house_nr2,
        AO_PLZ: this.postcode,
        AO_ORT: this.city,
        GERAET: this.counter_nr,
      };
      // remove empty parameters
      for (let param in params) {
        if (params[param] === "") {
          delete params[param];
        }
      }
      let response = ApiService.getTestData(params);
      response
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            // handle error
            let errMsg = await res.json();
            throw new Error(res.status + ": " + errMsg.error);
          }
        })
        .then((res) => {
          this.testdata = [...this.testdata, ...res.data];
          this.dataIsNotExhausted = !res.exhausted;
        })
        .catch((err) => {
          this.error = err.message;
          console.log(this.error);
        });
    },
    setCustomCase(entry) {
      this.$store.commit('setCase', entry)
      this.$store.commit('setCustomCase', entry)
      this.$router.push("/zpi-demo")
    }
  },
  computed: {
    // column titles of table
    columnMaloID: function () {
      return this.columnTitle == "Standard" ? "Malo_ID" : "EXT_UI";
    },
    columnStreet() {
      return this.columnTitle == "Standard" ? "Straße" : "AO_STR";
    },
    columnHS_Nr() {
      return this.columnTitle == "Standard" ? "Hausnr." : "AO_HSNR1";
    },
    columnsHS_Nr2() {
      return this.columnTitle == "Standard" ? "-zusatz" : "AO_HSNR2";
    },
    columnPLZ() {
      return this.columnTitle == "Standard" ? "PLZ" : "AO_PLZ";
    },
    columnOrt() {
      return this.columnTitle == "Standard" ? "Ort" : "AO_ORT";
    },
    columnName1() {
      return this.columnTitle == "Standard" ? "Vorn." : "GP_NAME1";
    },
    columnName2() {
      return this.columnTitle == "Standard" ? "Nachn." : "GP_NAME2";
    },
    columnGeraet() {
      return this.columnTitle == "Standard" ? "Zählernr." : "GERAET";
    },
  },
  mounted: function initialize() {
    if (localStorage.columnTitle) this.columnTitle = localStorage.columnTitle;
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.loadTestData()

      // making 'this' accessible in a different scope (the interval)
      let self = this;
      // periodically reload, in case the first call failed
      let loadInterval = setInterval( function() {
        if (self.testdata.length === 0) {
          self.loadTestData()
        } else {
          clearInterval(loadInterval)
        }
      }, 2000)
      // setup for infinite-scoll observer
      this.observerOptions = {
        root: this.$refs.data_body,
      }
    });
  },
  watch: {
    columnDescriptions(value) {
      localStorage.columnDescriptions = value;
    },
  },
};
</script>