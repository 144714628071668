<template>
  <div >
    <!--
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
    -->

    <div class="hero">
      <div class="hero-head">
        <a href="https://energy4u.org/">
          <img src="./assets/E4U-Logo.jpg" alt="Energy4U Logo" class="logo" />
        </a>
      </div>
      <div class="container">
        <div class="hero-body">
          <h3 class="subtitle has-text-primary">Machine Learning</h3>
          <h1 class="title has-text-primary">
            Automatische Zählpunktidentifikation
          </h1>
        </div>
      </div>
    </div>
    <div class="main-content">
      <div class="content container">
        <div class="notification is-danger" v-if="globalError">
          <button class="delete" @click="clearGlobalError"></button>
          <p>{{ globalError }}</p>
          <p>Bitte versuchen Sie es später nochmal.</p>
        </div>
        <div class="tabs">
          <ul>
            <li>
              <router-link to="zpi-demo">ZPI-Demo</router-link>
            </li>
            <li>
              <router-link to="testdata">Testdaten</router-link>
            </li>
          </ul>
        </div>
      </div>
      <router-view v-if="initialized" />
    </div>
    <footer>
      <div class="container">
        <div class="level">
          <div class="level-item has-text-centered">
            <a href="https://energy4u.org/impressum/" style="color: #ffffff"
              >Impressum</a
            >
          </div>
          <div class="level-item has-text-centered">
            <a href="https://energy4u.org/datenschutz/" style="color: #ffffff"
              >Datenschutz</a
            >
          </div>
          <div class="level-item has-text-centered">
            <a
              href="https://energy4u.org/allgemeine-geschaeftsbedingungen/"
              style="color: #ffffff"
              >AGB</a
            >
          </div>

          <div class="level-item footer-image-wrapper">
            <a
              href="https://atos.net/de/deutschland"
              target="_blank"
              h-use-smooth-scroll="true"
              rel="noopener"
            >
              <img
                src="https://energy4u.org/wp-content/uploads/2020/09/cropped-cropped-logo-atos.png"
                alt="Logo Atos" class="footer-image"
              />
            </a>
          </div>

          <div class="level-item">
            <div class="footer-copyright">
              © 2022 Copyright ENERGY4U | All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
// custom styles (Energy4U Colors)
$yellow: #ffd23f;
$turquoise: rgb(0, 178, 169);
$blue: rgb(0, 102, 162);
$cyan: rgb(64, 146, 228);
$grey-dark: rgb(138, 138, 138);
$grey: rgb(194, 194, 194);
$primary: $yellow;
$warning: $turquoise;
//$text: #353535;
@import "../node_modules/bulma";
@import "../node_modules/bulma-switch";
@import "~@creativebulma/bulma-divider";
@import "~@creativebulma/bulma-tooltip";
@import "../static/styles.scss";
</style>
<script>
import ApiService from "@/apiService";
export default {
  data() {
    return {
      globalError: "",
      initialized: false,
    };
  },
  computed: {
    testCases: {
      get() {
        return this.$store.getters.testCases;
      },
      set(value) {
        this.$store.commit("setTestCases", value);
      },
    },
  },
  created: function loadTestCases() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      // initial loading
      if (this.$store.getters.selectedCase === "") {
        let response = ApiService.getTestCases();
        response
          .then(async (res) => {
            if (res.ok) {
              return res.json();
            } else {
              // handle error
              let errMsg = await res.json();
              throw new Error(res.status + ": " + errMsg.error);
            }
          })
          .then((res) => {
            this.testCases = res;
            this.initialized = true;
          })
          .catch((err) => {
            //this.globalError = err.message;
            this.globalError = "Die Daten konnten nicht vom Server abgerufen werden."
          });
      }
    });
  },
};
</script>