<!-- Observer.vue -->
<!-- A component to watch the scroll behavior of the user (infinite-scroll)-->
<template>
  <div class="observer">
      <progress
          class="progress is-small is-primary block"
          max="100"
        ></progress>
  </div>
</template>

<script>
export default {
  props: ['options'],
  data: () => ({
    observer: null,
  }),
  mounted() {
    const options = this.options || {};
    // entry references the first element of 'entries'-array
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit("intersect");
      }
    }, options);

    this.observer.observe(this.$el);
  },
  destroyed() {
    this.observer.disconnect();
  },
};
</script>