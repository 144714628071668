<!-- A button to switch between two values -->
<template>
    <div>
            <label for="switchButton" class="switch-button-label">{{labelText}}</label>
            <button name="switchButton" class="button switch-button has-background-link-light" @click="switchValue">{{value}}</button>
        </div>
</template>

<script>
export default {
    name: 'SwitchButton',
    props: {
        labelText: String,
        storage: String,
        value1: String,
        value2: String,
    },
    data () {
        return {
            value: this.value1,
        }
    },
    methods: {
        switchValue(e) {
            e.preventDefault();
            this.value = this.value === this.value1 ? this.value2 : this.value1;
            localStorage[this.storage] = this.value;
            this.$emit('columnSwitch', this.value);
        },
    },
    mounted: function initialize() {
        // read value from local storage or set initial value
      if (localStorage[this.storage]) {
          this.value = localStorage[this.storage]
        }
      else {
          localStorage[this.storage] = this.value;
      }
  }
}
</script>