const url = 'api/'

module.exports = {
    async getCase(data) {
        let reqUrl = url + 'case';
        const response = await fetch(reqUrl, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
          });
        return response;
    },
    async getTestCases() {
      let reqUrl = url + 'testcases';
      const response = await fetch(reqUrl, {
        method: 'GET',
        mode: 'cors',
        
      });
      return response;
    },
    async getDefaultLieferantenResults(){
      let reqUrl = url + 'defaultlieferantenresults';
      const response = await fetch(reqUrl, {
        method: 'GET',
        mode: 'cors',
      });
      return response;
    },
    async getDefaultNetzbetreiberResults(){
      let reqUrl = url + 'defaultnetzbetreiberresults';
      const response = await fetch(reqUrl, {
        method: 'GET',
        mode: 'cors',
      });
      return response;
    },
    async getTestData(params={}) {
      console.log('GetTestData')
      //let reqUrl = new URL('http://localhost:5000/' + url + 'testdata');
      let reqUrl = url + 'testdata?'
      //Object.keys(params).forEach(key => reqUrl.searchParams.append(key, params[key]))
      
      Object.keys(params).forEach(key => {
        reqUrl += key + '=' + params[key] + "&";
      })
      const response = await fetch(reqUrl.slice(0, -1), {
        method: 'GET',
        mode: 'cors', 
      });
      return response;
    }
}