import { createRouter, createWebHashHistory } from 'vue-router'
import ZpiDemo from '../views/ZpiDemo.vue'
import TestData from '../views/TestData.vue'

const routes = [
  {
    path: '/',
    name: 'ZpiDemo',
    component: ZpiDemo
  },
  {
    path: '/testdata',
    name: 'TestData',
    component: TestData
  },
  { path: '/:pathMatch(.*)*', name: 'catch-all', component: ZpiDemo },/*
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/About.vue')
  }*/
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { el: ".tabs", top: 0 }
    }
  },
})

export default router
