import { createStore } from 'vuex'

const CUSTOMCASE = "Eigener Testfall"

export default createStore({
  state: {
    counter_nr: "",
    first_name: "",
    last_name: "",
    street: "",
    house_nr: "",
    house_nr2: "",
    postcode: "",
    city: "",
    malo_id: "",
    testCases: {},
    selectedCase: "",
    model: "Netzbetreiber",
  },
  getters: {
    counter_nr: state => state.counter_nr,
    first_name: state => state.first_name,
    last_name: state => state.last_name,
    street: state => state.street,
    house_nr: state => state.house_nr,
    house_nr2: state => state.house_nr2,
    postcode: state => state.postcode,
    city: state => state.city,
    malo_id: state => state.malo_id,
    testCases: state => state.testCases,
    selectedCase: state => state.selectedCase,
    model: state => state.model,
  },
  mutations: {
    setCounter_nr(state, c_nr) {
      state.counter_nr = c_nr
    },
    setFirst_name(state, name) {
      state.first_name = name
    },
    setLast_name(state, name) {
      state.last_name = name
    },
    setStreet(state, street) {
      state.street = street
    },
    setHouse_nr(state, h_nr) {
      state.house_nr = h_nr
    },
    setHouse_nr2(state, h_nr) {
      state.house_nr2 = h_nr
    },
    setPostcode(state, postcode) {
      state.postcode = postcode
    },
    setCity(state, city) {
      state.city = city
    },
    setMaloId(state, id){
      state.malo_id = id
    },
    setResultsLief(state, results) {
      state.testCases[state.selectedCase].resultsLief = results;
    },
    setResultsNetz(state, results) {
      state.testCases[state.selectedCase].resultsNetz = results;
    },
    setCase(state, c) {
      state.counter_nr = c.GERAET;
      state.first_name = c.GP_NAME1;
      state.last_name = c.GP_NAME2;
      state.street = c.AO_STR;
      state.house_nr = c.AO_HSNR1;
      state.house_nr2 = c.AO_HSNR2;
      state.postcode = c.AO_PLZ;
      state.city = c.AO_ORT;
      state.malo_id = c.EXT_UI;
    },
    setTestCases(state, cases) {
      state.testCases = cases
    },
    setSelectedCase(state, n) {
      state.selectedCase = n
    },
    setCustomCase(state, c) {
      state.testCases[CUSTOMCASE] = {case: c, resultsNetz: [], resultsLief: []};
      this.commit('setSelectedCase', CUSTOMCASE)
    },
    updateCase(state, {caseName, key, value}) {
      state.testCases[caseName][key] = value
    },
    updateCustomCase(state, key, value) {
      state.testCases[CUSTOMCASE][key] = value
    },
    setModel(state, model) {
      state.model = model;
    }
  },
  actions: {
  },
  modules: {
  }
})
